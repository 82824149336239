<template>
    <section>
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <div class="page-header">
                            <h3 v-if="!editableSectionTitle" class="page-title">
                                {{sectionTitle}}
                                <span class="badge badge-outline-primary badge-pill pointer" @click="editableSectionTitle = !editableSectionTitle"><i class="fa fa-pencil"></i></span>
                            </h3>
                            <b-form-group v-else>
                                <b-input-group>
                                    <b-form-input placeholder="Username" v-model="sectionTitle"></b-form-input>
                                    <b-input-group-text slot="append" class="bg-primary text-white pointer" @click="editableSectionTitle = !editableSectionTitle">
                                    <span><i class="fa fa-save"></i></span>
                                    </b-input-group-text>
                                </b-input-group>
                            </b-form-group>
                            <nav aria-label="breadcrumb">
                                <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="javascript:void(0);">Accueil</a></li>
                                <li class="breadcrumb-item active" aria-current="page">Carousel</li>
                                </ol>
                            </nav>
                        </div>
                        <!-- choix des types de données à ajouter -->
                        <div class="row">
                            <div class="col-md-12 mb-3">
                                De quelle manière voulez vous utiliser ce Carousel?
                            </div>
                            <div class="col-md-6">
                                <h6>Créer des slides</h6> 
                                <b-button class="btn btn-gradient-success btn-fw">Slides</b-button>
                                <p class="text-muted mt-3">Cette méthode consiste à créer un par un tous les slides et leurs contenus. Vous aurez plus de choix sur la conception graphique ( Taille des textes, alignements ... )</p>
                            </div>
                            <div class="col-md-6">
                                <h6>Créer une collection de slides</h6>
                                <b-button disabled class="btn btn-gradient-dark btn-fw" style="cursor:not-allowed">Collection</b-button>
                                <p class="text-muted mt-3">Cette méthode consiste à combiner une 'entité' avec un carousel. Ceux-ci seront générés automatiquement. Il sera impossible de modifier les contenus des slides. Exemple: Carousel de produits</p>
                            </div>
                        </div>

                        <!-- Si le choix s'est porté sur créer des slides  -->
                        <div class="row mt-4">
                            <div class="col-md-12">
                                <div class="page-header">
                                    <h4 class="page-title">Créer des slides</h4>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <b-carousel class="pointer" id="carousel" controls indicators background="#ababab" :interval="3000">
                                    <b-carousel-slide>
                                        <img v-b-modal.modal-1 slot="img" :src="require('../../../assets/images/import/add-picture.png')" alt="slide image">
                                        <h3>Créer un nouveau slide</h3>
                                        <p>Cliquer ici pour ajouter un nouveau slide</p>
                                    </b-carousel-slide>
                                </b-carousel>
                            </div>
                            <!-- modal d'ajout élément -->
                            <b-modal id="modal-1" title="Ajouter un nouveau slide" hide-footer>
                                <form class="forms-sample">
                                    <!-- Importation d'image du carousel -->
                                        <b-form-group label="Importer une image"  label-for="cardImage">
                                            <b-form-file v-model="file" id="inpu8" :state="Boolean(file)" @change="onFileSelected" placeholder="Choisir ou glisser l'image ici"></b-form-file>
                                        </b-form-group>
                                    <hr>
                                    <!-- type de texte -->
                                    <h4 class="text-muted mb-4">Texte dans le carousel</h4>
                                    <span class="d-flex justify-content-between" v-for="(description, descriptionId) in carouselDescription" :key="descriptionId">
                                        <!-- <p>{{description}}</p> -->
                                        <heading v-if="description.element=='heading'" :text="description.data['heading-text']" :justify="description.data['heading-justify']" class="pointer"/>
                                        <paragraph v-else-if="description.element=='paragraph'" :text="description.data['paragraph-text']" :justify="description.data['paragraph-justify']"></paragraph>
                                        
                                        <span>
                                            <!-- <i v-if="showEditText" class="fa fa-ban pointer mx-2" @click="cancelSelectText()"></i> -->
                                            <i class="fa fa-pencil pointer mx-4" @click="selectText(description)"></i>
                                            <i class="fa fa-trash pointer" @click="deleteText(description)"></i>
                                        </span>
                                    </span>
                                    <hr>
                                    <h4 class="text-muted mb-4">Ajouter un texte</h4>
                                    <b-form-group label="Type de texte"  label-for="text-element">
                                        <b-form-select type="text" id="text-element" :options="textElement" v-model="selectedTextElement" placeholder="Sélectionner le type de texte" >
                                        </b-form-select>
                                        <!-- <p>{{selectedTextElement}}</p> -->
                                    </b-form-group>

                                    <!-- Taille du titre -->
                                    <b-form-group v-show="selectedTextElement=='heading'" label="Taille du titre "  label-for="text-element">
                                        <b-form-select type="text" id="text-element" v-model="selectedTextElementSize" :options="textElementSize"  placeholder="Sélectionner le type de texte">
                                        </b-form-select>
                                        <!-- <p>{{selectedTextElementSize}}</p> -->
                                    </b-form-group>

                                    <!-- Alignement de texte -->
                                    <b-form-group label="Alignement du texte"  label-for="text-alignment">
                                        <b-form-select type="text" id="text-alignment" v-model="selectedTextElementAlignment" :options="textElementAlignment"  placeholder="Sélectionner le type de texte">
                                        </b-form-select>
                                        <!-- <p>{{selectedTextElementAlignment}}</p> -->
                                    </b-form-group>
                                    <!-- le texte en personne -->
                                    <b-form-group label="Texte"  label-for="text">
                                        <b-form-textarea type="text" id="text" placeholder="Texte" v-model="carouselText" :rows="3" :max-rows="6"></b-form-textarea>
                                    </b-form-group>
                                    <div class="d-flex justify-content-end">
                                        <b-button v-if="!showEditText" class="btn btn-gradient-primary" @click="addText()">Ajouter le texte</b-button>
                                        <span v-else>
                                            <button class="btn btn-link" @click="cancelEditingText()">Annuler</button>
                                            <b-button class="btn btn-gradient-info" @click="editText()">Modifier le texte</b-button>
                                        </span>
                                    </div>
                                    <hr>

                                    <h4 class="text-muted mb-4">Bouton</h4>
                                    <b-form-checkbox
                                        id="button"
                                        v-model="button"
                                        name="button"
                                        value="button"
                                        unchecked-value="no_button"
                                        >
                                        Bouton
                                    </b-form-checkbox>
                                    <b-form-group v-show="button=='button'" class="mt-4" label="Titre du bouton">
                                        <b-form-input v-model="carouselButton" placeholder="Entrer le titre du bouton"></b-form-input>
                                    </b-form-group>
                                    <b-form-group v-show="button=='button'" label="Lien du bouton">
                                        <b-form-input v-model="carouselButtonLink" placeholder="Entrer le lien du bouton"></b-form-input>
                                    </b-form-group>
                                    <b-form-group v-show="button=='button'" label="Alignement du bouton">
                                        <b-form-select type="text" id="text-element" v-model="selectedButtonAlignment" :options="buttonAlignment"  placeholder="Sélectionner le type de texte">
                                        </b-form-select>
                                        <!-- <p>{{selectedButtonAlignment}}</p> -->
                                    </b-form-group>

                                    <div class="d-flex justify-content-end">
                                        <b-button class="btn btn-link" variant="light" @click="cancelAddCarousel()">Annuler</b-button>
                                        <b-button @click="submitImageAndCarousel()" variant="success" class="mr-2">Créer le carousel </b-button>
                                    </div>
                                </form>
                            </b-modal>

                            <div class="col-md-12 mt-4">
                                <div class="page-header">
                                    <h4 class="page-title">Représentation actuelle du carousel</h4>
                                </div>
                            </div>
                            <!-- affichage à partir du composant carousel -->
                            <div class="col-md-12" v-for="(block, blockId) in section.blocks" :key="blockId">
                                <carousel :slides="block.data.slides">
                                </carousel>
                            </div>
                        </div>
                        <div class="card-footer d-flex justify-content-end mt-2">
                            <button class="btn btn-link" @click="cancelCreateCarousel()">Retour</button>
                            <button class="btn btn-gradient-primary" @click="saveCarousel()">Enregistrer</button>
                        </div>
                    </div> <!-- /card body -->
                    <!-- <button @click="showSnotify">test</button> -->
                    <vue-snotify></vue-snotify>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios'
import Carousel from '../../../components/element/carousel.vue'
import paragraph from '../../../components/element/text/paragraph.vue'
import Heading from '../../../components/element/text/heading.vue'
export default {
    name: 'add-section-carousel',
    components: {
        Carousel,
        paragraph,
        Heading
    },
    data () {
        return {
            ulid: '',
            editableSectionTitle: false,
            sectionTitle: '',
            section: '' ,
            carouselTitle:'',
            selectedTextElement: '',
            textElement: [  
                { value: 'heading', text:'En-tête'},
                { value: 'paragraph', text:'paragraphe'}
            ],
            textElementSize: [
                { value: 'h1', text: 'En-tête 1'},
                { value: 'h2', text: 'En-tête 2'},
                { value: 'h3', text: 'En-tête 3'},
                { value: 'h4', text: 'En-tête 4'},
                { value: 'h5', text: 'En-tête 5'},
                { value: 'h6', text: 'En-tête 6'}
            ],
            textElementAlignment: [
                { value: 'left', text: 'Gauche'},
                { value: 'right', text: 'Droite'},
                { value: 'center', text: 'Centre'}
            ],
            selectedTextElementSize: '',
            selectedTextElementAlignment: '',
            carouselText:'',
            carouselDescription: [],
            button: 'no_button',
            carouselButton: '',
            buttonAlignment: [
                { value: 'justify-content-start', text: 'Gauche'},
                { value: 'justify-content-end', text: 'Droite'},
                { value: 'justify-content-center', text: 'Centre'}
            ],
            selectedButtonAlignment: '',
            carouselButtonLink:'',
            indexOfDescription: 0,
            showEditText: false,
            file: null
        }
    },
    methods: {
        editSectionTitle () {
            this.editableSectionTitle = !this.editableSectionTitle
            console.log(this.editableSectionTitle);
        },
        getSectionInfo (ulid) {
            axios.get(`sections/${ulid}`)
            .then (resSectionInfo => {
                console.log({resSectionInfo})
                this.section = resSectionInfo.data
                this.sectionTitle = resSectionInfo.data.title
                console.log('section in getSectionInfo', this.section)
                })
            .catch (errSectionInfo => console.log({errSectionInfo}))
        },
        addElement() {
            console.log('ajouter un nouveau slide');
        },
        addText () {
            let text = this.carouselDescription
            if ( this.selectedTextElement =='paragraph') {
                text.push(
                    {
                        "order": `${text.length + 1}`,
                        "name" :"Message de bienvenue",
                        "element" : `${this.selectedTextElement}`,
                        "data": {
                            "paragraph-text": `${this.carouselText}`,
                            "paragraph-justify": `${this.selectedTextElementAlignment}`
                            }
                    }
                )
            } else if ( this.selectedTextElement =='heading') {
                text.push(
                    {
                        "order": 1,
                        "name" : "titre",
                        "element": `${this.selectedTextElement}`,
                        "data": { 
                            "heading-text": `${this.carouselText}`,
                            "heading-justify":`${this.selectedTextElementAlignment}`,
                            "heading-type": `${this.selectedTextElementSize}`
                        }
                    }
                )
            } else {
                alert ('Sélectionner un type de texte')
            }
            this.carouselDescription= text
            console.log('text pushé', text);
            console.log('carouselDescrit pushé', this.carouselDescription);
            this.clearInputForm()
        },
        editText () {
            this.carouselDescription.splice(this.indexOfDescription, 1)
            this.addText()
        },
        deleteText(description) {
            const descriptionIndex = this.carouselDescription.indexOf(description)
            this.carouselDescription.splice(descriptionIndex,1)
            this.$snotify.warning('Texte supprimé', {
            timeout: 2000,
            showProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            })
        },
        selectText(description) {
            this.showEditText = true
            console.log({description})
            this.indexOfDescription = this.carouselDescription.indexOf(description)
            console.log(this.carouselDescription.indexOf(description));
            if (description.element == 'heading') {
                this.selectedTextElement = description.element
                this.selectedTextElementAlignment = description.data['heading-justify']
                this.selectedTextElementSize = description.data['heading-type']
                this.carouselText = description.data['heading-text']
            } else if ( description.element == 'paragraph') {
                this.selectedTextElement = description.element
                this.selectedTextElementAlignment = description.data['paragraph-justify']
                this.carouselText = description.data['paragraph-text']
            } else {
                console.log('il n\' y a pas de texte à modifier')
            }
        },
        cancelEditingText () {
            this.selectedTextElement = ''
            this.selectedTextElementAlignment = ''
            this.carouselText= ''
            this.showEditText = false
        },
        addCarousel (imageUrl) {
            let sectionClone = this.section.blocks[0].data.slides
            console.log({sectionClone});
            sectionClone.push (
                   {
                        "carousel-image": imageUrl,
                        "carousel-title": 
                        {
                            "order": 1,
                            "name" : "titre",
                            "element": `${this.selectedTextElement}`,
                            "data": { 
                                "heading-text": `${this.carouselText}`,
                                "heading-justify":`${this.selectedTextElementAlignment}`,
                                "heading-type": `${this.textElementSize}`
                            }
                        },
                        "carousel-description": this.carouselDescription ,
                        "carousel-button": this.carouselButton,
                        "carousel-button-link": this.carouselButtonLink,
                        "carousel-button-alignment": this.selectedButtonAlignment
                    }
            )
            console.log('sectionClone après push',sectionClone);
            //notification succès
            this.$snotify.success('Slide ajouté avec succès', {
                timeout: 2000,
                showProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true
                })
            this.selectedButtonAlignment=''
            this.carouselButton=''
            this.carouselButtonLink=''
            this.carouselDescription=[]
            this.saveCarousel()
            this.$bvModal.hide('modal-1')
        },
        showSnotify () {
            console.log('show snofify');
            console.log(this.$snotify);
            this.$snotify.success('Slide ajouté avec succès', {
            timeout: 2000,
            showProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
        });
      },
        cancelAddCarousel (){
            this.selectedButtonAlignment=''
            this.carouselButton=''
            this.carouselButtonLink=''
            this.carouselDescription=[]
            this.$bvModal.hide('modal-1')
        },
        saveCarousel() {
            console.log('enregistrement du carousel');
            axios.put (`sections/${this.ulid}`, {
                "title" : this.sectionTitle,
                "blocks" : this.section.blocks
            },
            {
                headers: {
                    'X-AUTH-TOKEN' : localStorage.getItem('token')
                }
            })
            .then (resPutSection => {
                this.$snotify.success('Carousel enregistré avec succès', {
                    timeout: 2000,
                    showProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                })
                console.log({resPutSection})
            })
            .catch (errPutSection => console.log({errPutSection}))
        },
        cancelCreateCarousel () {
            this.$router.go(-1)
        },
        clearInputForm() {
            this.selectedTextElement=''
            this.selectedTextElementSize=''
            this.selectedTextElementAlignment=''
            this.carouselText=''
        },
        // upload d'image
        onFileSelected (event) {
          this.file=event.target.files[0]
        },
        submitImageAndCarousel () {
          const fd = new FormData
          fd.append ('imgfile', this.file)
          fd.append ('alt', this.file)
          axios.post('imgs', fd, 
          {
            headers: {
              'X-AUTH-TOKEN': localStorage.getItem('token'),
              'Content-Type': 'multipart/form-data'
            }
          })
          .then(resSubmitImage=> {
            console.log('resSubmitImage',resSubmitImage)
            let imageUrl = resSubmitImage.data.url
            this.addCarousel(imageUrl)
            })
          .catch(errSubmitImage=> console.log(errSubmitImage))
        }
    },
   beforeRouteLeave (to, from, next) {
       const answer = window.confirm('Les modifications non enregistrées seront perdues ! Voulez-vous continuer ?')
        if (answer) {
            next()
        } else {
            next(false)
        }
    },
    mounted () {
        this.ulid = this.$route.params.ulid
        this.getSectionInfo(this.ulid)
    }
}
</script>
<style scoped>
.crud-carousel {
    position: absolute;
    top: -120%;
    right: 0%
}
</style>